.me-CartIcon {
  cursor: pointer;
  display: flex;
  & > a {
    display: flex;
  }
}
.me-CartPreview {
  &.cartpre-s-center,
  &.cartpre-s-full {
    left: 50%;
    transform: translateX(-50%);
  }
  &.cartpre-s-left {
    left: 0;
  }
  &.cartpre-s-right {
    right: 0;
  }
  max-height: 0;
  transition: max-height 0.5s ease;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  max-width: 100%;
  &.show {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .button-wrap {
    margin-top: 10px;
  }
}

.me-CartAddress {
  select[value=''] {
    color: #ccc;
  }
}
